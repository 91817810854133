<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item> 销售管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/dataExtract' }"
          >资料提取
        </el-breadcrumb-item>
        <el-breadcrumb-item>新建</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form ref="form" :model="form" label-width="120px" size="mini">
        <el-form-item label="报单ID：" label-position="left" prop="name">
          <el-input
            v-model="searchData1.sales_id"
            placeholder="报单ID"
            style="width: 300px"
          />
          <el-button
            :loading="sales"
            class="margin-l-sm"
            size="mini"
            type="primary"
            @click="getSales"
            >搜索
          </el-button>
        </el-form-item>

        <el-form-item label="邮箱：" label-position="left" prop="email">
          <span>{{ form.email }}</span>
        </el-form-item>
        <el-form-item label="备注：" label-position="left" prop="remark">
          <el-input
            v-model="form.remark"
            placeholder="请输入备注"
            style="width: 300px"
            type="textarea"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="附件：" label-position="left" prop="name">
          <el-button size="mini" type="primary" @click="addFile"
            >选择附件
          </el-button>
          <div v-for="(item, index) in fileList" :key="item.id" class="flex">
            <div class="margin-r-sm">{{ item.name }}</div>
            <div class="margin-r-sm">{{ unitConversion(item.size) }}</div>
            <div class="margin-r-sm">{{ item.ext }}</div>
            <div class="margin-r-sm">
              <el-button type="text" @click="delFile(index)">删除</el-button>
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="onSubmit('form')"
            >保存
          </el-button>
          <router-link class="router-link" to="/dataExtract">
            <el-button size="small" style="margin-left: 10px">取消</el-button>
          </router-link>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog :visible.sync="file" append-to-body title="附件" width="60%">
      <div class="margin-b-sm">
        <el-input
          v-model="searchData.name"
          class="margin-r-sm"
          placeholder="附件名称"
          style="width: 120px"
        />
        <el-button size="mini" type="primary" @click="search">搜索</el-button>
      </div>
      <div v-infinite-scroll="load" class="table">
        <el-table
          ref="multipleTable"
          v-loading="fileLoading"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          style="width: 100%; background: #fff"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column align="center" label="ID" property="id" width="80" />
          <el-table-column align="center" label="附件名称" property="name" />
          <el-table-column align="center" label="大小" property="size">
            <template slot-scope="scope">
              <span>{{ unitConversion(scope.row.size) }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="扩展名" property="ext" />
          <el-table-column align="center" label="上传者" property="user_name" />
          <el-table-column
            align="center"
            label="创建时间"
            property="created_at"
          />
          <el-table-column
            align="center"
            label="更新时间"
            property="updated_at"
          />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="file = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "add",
  data() {
    return {
      loading: false,
      form: {
        data_files: [],
      },
      searchData1: {},
      table: [],
      sales: false,
      dialogVisible: false,
      tableData: [],
      file: false,
      fileLoading: false,
      dialog: true,
      searchData: {},
      page: 1,
      pageSize: 10,
      fileList: [],
    };
  },
  methods: {
    ...mapActions("dataExtract", ["selectSales", "salesDataFileApplyAdd"]),
    ...mapActions("mailbox", ["attachmentFileList"]),

    unitConversion(size) {
      return (size / 1024 / 1024).toFixed(2) + "M";
    },
    delFile(index) {
      this.fileList.splice(index, 1);
      this.form.data_files.splice(index, 1);
    },
    async getFileList() {
      try {
        this.file = true;
        this.fileLoading = true;
        let form = {
          page: this.page,
          pageSize: this.pageSize,
          ...this.searchData,
        };
        const { data } = await this.attachmentFileList(form);
        this.tableData = this.tableData.concat(data.list);
        if (this.page * this.pageSize > data.total) {
          this.dialog = false;
        }
      } finally {
        this.fileLoading = false;
      }
    },
    search() {
      this.dialog = true;
      this.page = 1;
      this.tableData = [];
      this.getFileList();
    },
    async load() {
      if (this.dialog) {
        console.log(111);
        let _this = this;
        ++_this.page;
        await _this.getFileList();
        const rows = _this.getMatchingValues(
          this.tableData,
          this.form.data_files
        );
        _this.$nextTick(() => {
          rows.forEach((row) => {
            _this.$refs.multipleTable.toggleRowSelection(row);
          });
        });
      }
    },
    getMatchingValues(array1, array2) {
      const result = [];
      for (let i = 0; i < array1.length; i++) {
        const item1 = array1[i];
        for (let j = 0; j < array2.length; j++) {
          const item2 = array2[j];
          if (item1.id === item2) {
            result.push(item1);
            break;
          }
        }
      }
      return result;
    },
    removeDuplicatesBySameId(arr) {
      let result = arr.reduce(function (unique, obj) {
        // 检查unique数组中是否已存在具有相同id的对象
        let exists = unique.some(function (item) {
          return item.id === obj.id;
        });

        // 如果不存在具有相同id的对象，则将当前对象添加到unique数组中
        if (!exists) {
          unique.push(obj);
        }

        return unique;
      }, []);

      return result;
    },
    handleSelectionChange(value) {
      this.fileList.push(...value);
      this.fileList = this.removeDuplicatesBySameId(this.fileList);

      this.form.data_files.push(...this.fileList.map((v) => v.id));
      this.form.data_files = Array.from(new Set(this.form.data_files));
    },
    async addFile() {
      this.dialog = true;
      let _this = this;
      _this.page = 1;
      this.tableData = [];
      this.searchData = {};
      await _this.getFileList();
      const rows = _this.getMatchingValues(
        this.tableData,
        this.form.data_files
      );
      _this.$nextTick(() => {
        rows.forEach((row) => {
          _this.$refs.multipleTable.toggleRowSelection(row);
        });
      });
    },
    setForm() {
      this.form.email = this.table.find(
        (v) => v.id === this.form.sales_id
      ).email;
      this.dialogVisible = false;
    },
    async getSales() {
      try {
        if (this.searchData1.sales_id) {
          this.dialogVisible = true;
          this.sales = true;
          const { data } = await this.selectSales(this.searchData1);
          if (!data.id) {
            return this.$message.error("数据不存在");
          }
          if (data.exist) {
            this.form.email = data.email;
            this.form.sales_id = data.id;
          } else this.form.email = "此报单邮箱不存在";
        }
      } finally {
        this.sales = false;
      }
    },
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.form.email === "此报单邮箱不存在") {
            return this.$message.error("此报单邮箱不存在");
          }
          if (!this.form.data_files[0]) {
            return this.$message.error("附件不能为空");
          }
          try {
            this.loading = true;
            const { res_info } = await this.salesDataFileApplyAdd(this.form);
            if (res_info !== "ok") return;
            this.$message.success("创建成功");
            this.$router.go(-1);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.main {
  padding: 10px;
}

.title {
  width: 4em;
}

.recipients {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
}

.file-list {
  padding: 5px 0;
  //border-bottom: 1px solid #eee;
}

.item {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  justify-content: start;
}

.file {
  cursor: pointer;
}

.table {
  height: 50vh;
  overflow: auto;
}

.table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

/* 滚动条滑块 */
.table::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
}
</style>